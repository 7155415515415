var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("GeneralSubHeader", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("SVGIcon", {
                  staticClass: "kt-svg-icon mt-1 mr-3",
                  attrs: { "hex-color": "#0f88ef", name: "earth" },
                }),
                _c("span", { staticClass: "kt-font-bold ml-3" }, [
                  _vm._v(
                    " Constituents by " + _vm._s(_vm.$route.params.filter) + " "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "left-buttons",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
          {
            key: "buttons",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pr-2 pl-4",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "kt-portlet kt-portlet--tabs kt-portlet--height-fluid",
            },
            [
              _c("div", { staticClass: "kt-portlet__body" }, [
                _c("div", { staticClass: "kt-widget11" }, [
                  _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table" }, [
                      _vm._m(0),
                      _c(
                        "tbody",
                        _vm._l(_vm.profiles, function (item) {
                          return _c("tr", { key: item.id }, [
                            _c("td", [
                              _c(
                                "a",
                                {
                                  staticClass: "kt-widget11__title",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.openProfile(item)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              ),
                              _c("span", { staticClass: "kt-widget11__sub" }, [
                                _vm._v(" " + _vm._s(item.type) + " "),
                              ]),
                            ]),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "kt-font-brand kt-font-bolder kt-font-lg",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.randomIntFromInterval(2, 100)) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "kt-font-brand kt-font-bolder kt-font-lg",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.randomIntFromInterval(2, 100)) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "kt-font-brand kt-font-bolder kt-font-lg",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.randomIntFromInterval(2, 100)) +
                                    " "
                                ),
                              ]
                            ),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]),
                _vm._m(1),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("td", [_vm._v(" Name ")]),
        _c("td", { staticStyle: { width: "15%" } }, [
          _vm._v(" 2022"),
          _c("br"),
          _vm._v("Seals "),
        ]),
        _c("td", { staticStyle: { width: "15%" } }, [
          _vm._v(" 2020"),
          _c("br"),
          _vm._v("Seals "),
        ]),
        _c("td", { staticStyle: { width: "15%" } }, [
          _vm._v(" 2021"),
          _c("br"),
          _vm._v("Seals "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-pagination kt-pagination--brand" }, [
      _c("ul", { staticClass: "kt-pagination__links" }, [
        _c("li", { staticClass: "kt-pagination__link--first" }, [
          _c("a", { attrs: { href: "#" } }, [
            _c("i", { staticClass: "fa fa-angle-double-left kt-font-brand" }),
          ]),
        ]),
        _c("li", { staticClass: "kt-pagination__link--next" }, [
          _c("a", { attrs: { href: "#" } }, [
            _c("i", { staticClass: "fa fa-angle-left kt-font-brand" }),
          ]),
        ]),
        _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v("...")])]),
        _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v("29")])]),
        _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v("30")])]),
        _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v("31")])]),
        _c("li", { staticClass: "kt-pagination__link--active" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("32")]),
        ]),
        _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v("33")])]),
        _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v("34")])]),
        _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v("...")])]),
        _c("li", { staticClass: "kt-pagination__link--prev" }, [
          _c("a", { attrs: { href: "#" } }, [
            _c("i", { staticClass: "fa fa-angle-right kt-font-brand" }),
          ]),
        ]),
        _c("li", { staticClass: "kt-pagination__link--last" }, [
          _c("a", { attrs: { href: "#" } }, [
            _c("i", { staticClass: "fa fa-angle-double-right kt-font-brand" }),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "kt-pagination__toolbar" }, [
        _c(
          "select",
          {
            staticClass: "form-control kt-font-brand",
            staticStyle: { width: "60px" },
          },
          [
            _c("option", { attrs: { value: "10" } }, [_vm._v(" 10 ")]),
            _c("option", { attrs: { value: "20" } }, [_vm._v(" 20 ")]),
            _c("option", { attrs: { value: "30" } }, [_vm._v(" 30 ")]),
            _c("option", { attrs: { value: "50" } }, [_vm._v(" 50 ")]),
            _c("option", { attrs: { value: "100" } }, [_vm._v(" 100 ")]),
          ]
        ),
        _c("span", { staticClass: "pagination__desc" }, [
          _vm._v(" Displaying 10 of 230 records "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }