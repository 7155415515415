<template>
<div>
    <GeneralSubHeader>
        <template #title>
            <SVGIcon
                hex-color="#0f88ef"
                name="earth"
                class="kt-svg-icon mt-1 mr-3"
            />
            <span class="kt-font-bold ml-3">
                Constituents by {{ $route.params.filter }}
            </span>

            <!-- <small class="ml-3">{{ lastUpdated }}</small> -->
        </template>
        <template #left-buttons>
            <!-- what -->
        </template>
        <template #buttons>
            <!-- wat -->
        </template>
    </GeneralSubHeader>

    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pr-2 pl-4">
        <div class="kt-portlet kt-portlet--tabs kt-portlet--height-fluid">
            <div class="kt-portlet__body">
                <div class="kt-widget11">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <td>
                                        Name
                                    </td>
                                    <td style="width:15%">
                                        2022<br>Seals
                                    </td>
                                    <td style="width:15%">
                                        2020<br>Seals
                                    </td>
                                    <td style="width:15%">
                                        2021<br>Seals
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item) in profiles" :key="item.id">
                                    <td>
                                        <a
                                            href="#"
                                            class="kt-widget11__title"
                                            @click.stop.prevent="openProfile(item)"
                                        >
                                            {{ item.name }}
                                        </a>
                                        <span class="kt-widget11__sub">
                                            {{ item.type }}
                                        </span>
                                    </td>
                                    <td class="kt-font-brand kt-font-bolder kt-font-lg">
                                        {{ randomIntFromInterval(2, 100) }}
                                    </td>
                                    <td class="kt-font-brand kt-font-bolder kt-font-lg">
                                        {{ randomIntFromInterval(2, 100) }}
                                    </td>
                                    <td class="kt-font-brand kt-font-bolder kt-font-lg">
                                        {{ randomIntFromInterval(2, 100) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="kt-pagination kt-pagination--brand">
                    <ul class="kt-pagination__links">
                        <li class="kt-pagination__link--first">
                            <a href="#"><i class="fa fa-angle-double-left kt-font-brand" /></a>
                        </li>
                        <li class="kt-pagination__link--next">
                            <a href="#"><i class="fa fa-angle-left kt-font-brand" /></a>
                        </li>
                        <li>
                            <a href="#">...</a>
                        </li>
                        <li>
                            <a href="#">29</a>
                        </li>
                        <li>
                            <a href="#">30</a>
                        </li>
                        <li>
                            <a href="#">31</a>
                        </li>
                        <li class="kt-pagination__link--active">
                            <a href="#">32</a>
                        </li>
                        <li>
                            <a href="#">33</a>
                        </li>
                        <li>
                            <a href="#">34</a>
                        </li>
                        <li>
                            <a href="#">...</a>
                        </li>
                        <li class="kt-pagination__link--prev">
                            <a href="#"><i class="fa fa-angle-right kt-font-brand" /></a>
                        </li>
                        <li class="kt-pagination__link--last">
                            <a href="#"><i class="fa fa-angle-double-right kt-font-brand" /></a>
                        </li>
                    </ul>
                    <div class="kt-pagination__toolbar">
                        <select class="form-control kt-font-brand" style="width: 60px;">
                            <option value="10">
                                10
                            </option>
                            <option value="20">
                                20
                            </option>
                            <option value="30">
                                30
                            </option>
                            <option value="50">
                                50
                            </option>
                            <option value="100">
                                100
                            </option>
                        </select>
                        <span class="pagination__desc">
                            Displaying 10 of 230 records
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import Vue from 'vue';
import GeneralSubHeader from '../components/GeneralSubHeader.vue';
import Types from '../store/Types';

export default Vue.extend({
    name: 'BiliteracySealConstituents',
    components: {
        GeneralSubHeader,
    },
    data() {
        return {
            loading: false,
            show: false,
            showYearPicker: false,
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        title() {
            return this.$route.meta.title;
        },
        profiles() {
            const { filter } = this.$route.params;
            let output = [];
            if (filter == 'big5') output = [...this.$store.state.biliteracySeal.big5];
            if (filter == 'regions') output = [...this.$store.state.biliteracySeal.regions];
            if (filter == 'districts') output = [...this.$store.state.biliteracySeal.districts];
            if (filter == 'schools') output = [...this.$store.state.biliteracySeal.schools];
            return output
                .map((p, idx) => ({ name: p, type: filter, id: idx }))
                .slice(0, 8);
        },
    },
    methods: {
        randomIntFromInterval(min, max) { // min and max included
            return Math.floor(Math.random() * (max - min + 1) + min);
        },
        openProfile(profile) {
            this.$store.commit(Types.mutations.ADD_BILITERACY_PROFILE_HISTORY, profile);
            if (profile.type == 'big5') this.$router.push({ name: 'BiliteracySealBigFiveProfile', params: { id: profile.id } });
            if (profile.type == 'regions') this.$router.push({ name: 'BiliteracySealRegionalProfile', params: { id: profile.id } });
            if (profile.type == 'districts') this.$router.push({ name: 'BiliteracySealDistrictProfile', params: { id: profile.id } });
            if (profile.type == 'schools') this.$router.push({ name: 'BiliteracySealSchoolProfile', params: { id: profile.id } });
        },
    },
});

</script>

<style lang="scss">

td {
    vertical-align: middle !important;
}
</style>
